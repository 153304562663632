import Helmet from "react-helmet"
import React from "react"
import LearnMore from "src/components/learnmore"
import HomepageLayout from "src/layouts/homepage"
import CtadisclosureBing from "src/components/ctadisclosureBing"
import Ctadisclosurechff from "src/components/ctadisclosurechff"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const LearnMoreData = import("/src/components/learnmore/data/learnmore.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <Ctadisclosurechff  data={LearnMoreData}></Ctadisclosurechff>;
  }
  else{
    disclosure = <CtadisclosureBing data={LearnMoreData}></CtadisclosureBing>;
  }
  return(
    <HomepageLayout>
      <Helmet>

      <title>Save any web page easily - Easy Save</title></Helmet>
      <LearnMore data={LearnMoreData}>
        {disclosure}
      </LearnMore>
    </HomepageLayout>
  )
}
